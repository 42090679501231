// Thumbnails.tsx
import React from 'react';
import {ImageProps, LayoutPosition} from '../types';
import Thumbnail from './Thumbnail';

interface ThumbnailsProps {
  images: ImageProps[];
  selectedIndex: number;
  onThumbnailHover: (index: number) => void;
  thumbnailPosition: LayoutPosition;
  maxThumbnails?: number;
  onExtraClick?: () => void;
}

const Thumbnails: React.FC<ThumbnailsProps> = ({
                                                 images,
                                                 selectedIndex,
                                                 onThumbnailHover,
                                                 thumbnailPosition,
                                                 maxThumbnails = images.length,
                                                 onExtraClick,
                                               }) => {
  const isVertical = thumbnailPosition === 'left' || thumbnailPosition === 'right';
  const containerClass = isVertical ? 'flex flex-col space-y-2' : 'flex space-x-2';
  const showExtra = images.length > maxThumbnails;
  const normalImages = showExtra ? images.slice(0, maxThumbnails - 1) : images;

  return (
    <div className={containerClass}>
      {normalImages.map((img, index) => (
        <Thumbnail
          key={index}
          img={img}
          isSelected={selectedIndex === index}
          onThumbnailHover={() => onThumbnailHover(index)}
        />
      ))}
      {showExtra && (
        <Thumbnail
          img={images[maxThumbnails - 1]}
          isSelected={selectedIndex === normalImages.length}
          onThumbnailHover={() => onThumbnailHover(normalImages.length)}
          onClick={onExtraClick}
          extraCount={images.length - (maxThumbnails - 1)}
        />
      )}
    </div>
  );
};

export default Thumbnails;
