// Thumbnail.tsx
import React from 'react';
import ImageContainer from '../../../atoms/ImageContainer';
import {ImageProps} from '../types';

interface ThumbnailProps {
  img: ImageProps;
  isSelected: boolean;
  onThumbnailHover: () => void;
  onClick?: () => void;
  extraCount?: number;
}

const Thumbnail: React.FC<ThumbnailProps> = (
  {
    img,
    isSelected,
    onThumbnailHover,
    onClick,
    extraCount,
  }) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onThumbnailHover}
      className={`
        relative rounded focus:outline-none transition-all duration-300 ease-in-out
        ${isSelected ? 'ring-4 ring-gray-800/30' : ''}
      `}
    >
      <div className={`
        rounded w-16 h-16 overflow-hidden relative transition-all duration-300 ease-in-out
        ${isSelected ? 'ring-1 ring-gray-800' : ''}
      `}>
        <ImageContainer
          src={img.src}
          altText={img.alt || 'Thumbnail'}
          aspect="square"
          fit="cover"
        />
        {extraCount && (
          <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <span className="text-white font-bold text-lg">
              +{extraCount}
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default Thumbnail;
