import React from 'react';
interface ScrollableContainerProps {
  children: React.ReactNode;
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({children}) => (
  <div className="relative overflow-y-auto h-full">
    <div className="absolute top-0 left-0 w-full h-8 pointer-events-none bg-gradient-to-b from-white to-transparent z-10" />
    <div className="overflow-y-auto h-full">{children}</div>
    <div className="absolute bottom-0 left-0 w-full h-8 pointer-events-none bg-gradient-to-t from-white to-transparent z-10" />
  </div>
);


export default ScrollableContainer;
