(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("query-string"), require("@ergeon/core-components"), require("classnames"), require("moment"), require("html-react-parser"), require("@ergeon/file-storage"), require("axios"), require("lodash-humps"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "query-string", "@ergeon/core-components", "classnames", "moment", "html-react-parser", "@ergeon/file-storage", "axios", "lodash-humps"], factory);
	else if(typeof exports === 'object')
		exports["@ergeon/help-components"] = factory(require("react"), require("prop-types"), require("query-string"), require("@ergeon/core-components"), require("classnames"), require("moment"), require("html-react-parser"), require("@ergeon/file-storage"), require("axios"), require("lodash-humps"));
	else
		root["@ergeon/help-components"] = factory(root["react"], root["prop-types"], root["query-string"], root["@ergeon/core-components"], root["classnames"], root["moment"], root["html-react-parser"], root["@ergeon/file-storage"], root["axios"], root["lodash-humps"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__1949__, __WEBPACK_EXTERNAL_MODULE__1754__, __WEBPACK_EXTERNAL_MODULE__5827__, __WEBPACK_EXTERNAL_MODULE__992__, __WEBPACK_EXTERNAL_MODULE__5416__, __WEBPACK_EXTERNAL_MODULE__7773__, __WEBPACK_EXTERNAL_MODULE__6065__, __WEBPACK_EXTERNAL_MODULE__2742__, __WEBPACK_EXTERNAL_MODULE__1539__) => {
return 