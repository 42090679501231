import React, {useEffect, useRef, useState} from 'react';
import ImageContainer from '../../../atoms/ImageContainer';
import ImageSlider from '../../../molecules/ImageSlider';
import {ImageProps} from '../types';
import ScrollableContainer from './ScrollableContainer';
import Thumbnail from './Thumbnail';
import useImageContainerHeight from './useImageContainerHeight';

const FullGalleryDialog: React.FC<{ images: ImageProps[]; initialIndex?: number }> = ({ images, initialIndex = 0 }) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  useEffect(() => {
    setSelectedIndex(initialIndex);
  }, [initialIndex]);

  const imageContainerRef = useRef<HTMLDivElement>(null);

  const imageContainerHeight = useImageContainerHeight({imageContainerRef});

  return (
    <div className="
      bg-white w-full my-10 mx-5 rounded-lg p-2.5 md:p-5 flex gap-2.5 md:gap-5
   ">
      <div className="w-full">
        <div ref={imageContainerRef} className="hidden md:block">
          <ImageContainer
            src={images[selectedIndex].src}
            altText={images[selectedIndex].alt || 'Product image'}
            aspect="video"
            fit="contain"
          />
        </div>
        <div className="block md:hidden">
          <ImageSlider
            aspect="video"
            images={images.map(img=> img.src)}
            selectedIndex={selectedIndex} />
        </div>
      </div>
      <div className="hidden md:block">
        <ScrollableContainer>
          <div
            className={`flex flex-col p-1 gap-2.5 overflow-y-auto`}
            style={{ maxHeight: imageContainerHeight ? imageContainerHeight : 'auto' }}
          >
            {images.map((img, index) => (
              <Thumbnail
                key={index}
                img={img}
                isSelected={selectedIndex === index}
                onThumbnailHover={() => setSelectedIndex(index)} />
            ))}
          </div>
        </ScrollableContainer>
      </div>
    </div>

  );
};

export default FullGalleryDialog;
